<template>
  <list-template
    :search-config="searchConfig"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onSearch="onSearch"
    @onReset="onReset"
    @onChange="onChange"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onAdd="$router.push('./add')"
  >
  </list-template>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import { setConfigOption } from '@/utils'
import { get_grade3, get_school3, postApi2 } from '@/api'
export default {
  _config: {route: {path: "list", meta: {title: "列表", keepAlive: true}}},
  mixins: [tableListMixin],
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      get_school3().then(res => {
        setConfigOption({ list: this.searchConfig, data: res, key: 'school_id', label: 'school_name', value: 'id' })
      })
      get_grade3().then(res => {
        setConfigOption({ list: this.searchConfig, data: res, key: 'grade_id', label: 'name', value: 'id' })
      })
    },
    getData() {
      this.getDataApi('/api2/api/survey/interaction/topic-list')
    },
    handleClick(row, handle) {
      if (handle.type === 'view') {
        this.$router.push({ path: './detail', query: { id: row.id } })
      } else if (handle.type === 'delete') {
        this.$msgbox({
          title: '消息',
          message: '确定删除此课时?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: '继续删除',
          cancelButtonText: '点错了',
          beforeClose: (action, instance, done) => {
            if (action !== 'confirm') return done()
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            postApi2('/survey/interaction/topic-del', {id:row.id}).then(res=>{
              this.getData()
              this.$message({ message: '删除成功', type: 'success' })
              done()
            }).finally(() => {
              instance.confirmButtonLoading = false
            })
          }
        })
      }
    }
  },
  data() {
    return {
      searchConfig: [
        { tag: 'select', placeholder: '筛选校区', prop: 'school_id', options: [] },
        { tag: 'select', placeholder: '筛选年级', prop: 'grade_id', options: [] }
      ],
      tableConfig: [
        { prop: 'day', label: '上课时间' },
        { prop: 'status_name', label: '课节', render: (row) => `<span class="status">${row.class_start} - ${row.class_end}</span>` },
        { prop: 'class_name', label: '教学班' },
        { prop: 'school_name', label: '校区' },
        { prop: 'grade_name', label: '年级' },
        { prop: 'creator', label: '创建人' },
        { prop: 'created_at', label: '创建时间' },
        { label: '操作', handle: true }
      ],
    }
  }
}
</script>

<style lang="scss" scoped></style>
